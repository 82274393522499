<template>
  <div id="result-psychomotors">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <div class="columns is-multiline is-centered">
        <div class="column is-8">
          <b-field label="Term">
            <b-select
              id="term-id"
              v-model="termId"
              placeholder="Select a option"
              expanded
            >
              <option v-for="term in terms" :key="term.id" :value="term.id">
                {{ term.sessionTitle }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="column is-8">
          <p class="title is-6 is-bolder">Student's Psychomotors</p>
          <template>
            <template v-if="student">
              <!-- Name Section -->
              <section class="has-background-white-ter" style="padding: 10px">
                <div class="columns">
                  <div class="column">
                    <div class="media">
                      <div class="media-left">
                        <figure class="image is-48x48">
                          <img
                            class="is-rounded"
                            :src="student.imageUrl"
                            alt="avatar"
                            v-if="student.imageUrl"
                          />
                          <img
                            class="is-rounded"
                            src="@/assets/images/default_avatar.png"
                            alt="avatar"
                            v-else
                          />
                        </figure>
                      </div>
                      <div class="media-content">
                        <p class="title is-4" style="padding-top: 8px">
                          {{ student.name }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <!-- Psychomotor Section -->
              <section>
                <template v-if="student.resultPsychomotors">
                  <b-table
                    :data="student.resultPsychomotors.psychomotors"
                    hoverable
                  >
                    <b-table-column
                      field="#"
                      label="#"
                      width="5%"
                      numeric
                      v-slot="props"
                    >
                      {{
                        student.resultPsychomotors.psychomotors.indexOf(
                          props.row
                        ) + 1
                      }}
                    </b-table-column>

                    <b-table-column label="Name" width="60%" v-slot="props">{{
                      props.row.name
                    }}</b-table-column>

                    <b-table-column label="Value" width="5%" v-slot="props">
                      <b-field>
                        <b-input
                          type="text"
                          :id="`${props.row.name
                            .toLowerCase()
                            .replace(/\s/g, '-')}`"
                          :name="`${props.row.name
                            .toLowerCase()
                            .replace(/\s/g, '-')}`"
                          v-model="props.row.value"
                          @change.native="setRemark(props.row)"
                        ></b-input>
                      </b-field>
                    </b-table-column>

                    <b-table-column label="Remark" width="30%" v-slot="props">{{
                      props.row.remark
                    }}</b-table-column>

                    <template slot="footer">
                      <p class="control has-text-right">
                        <a
                          class="button btn-120 is-primary is-capitalized"
                          @click="submit()"
                          >{{ context }}</a
                        >
                      </p>
                    </template>
                  </b-table>
                </template>
                <template v-else>
                  <div class="has-text-right">
                    <button
                      class="button is-white has-text-info"
                      style="font-size: 1.5em"
                      @click="initializeCreate()"
                    >
                      <span class="icon">
                        <fa-icon icon="plus-circle" size="lg" />
                      </span>
                    </button>
                  </div>
                </template>
              </section>
            </template>
            <section class="section" v-else>
              <div class="content has-text-grey has-text-centered">
                <p>
                  <fa-icon icon="info-circle" class="fa-5x" />
                </p>
                <p>Select a school class to fetch students list</p>
              </div>
            </section>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { fetchUser } from '@/assets/js/app_info.js'
import TERMS from '../../../graphql/term/Terms.gql'
import PSYCHOMOTORS from '../../../graphql/psychomotor/Psychomotors.gql'
import { crudNotification } from '../../assets/js/notification'

export default {
  name: 'result-psychomotors',
  data() {
    return {
      pageTitle: 'Previous Result Psychomotors',
      user: null,
      terms: [],
      termId: null,
      schoolId: null,
      icon_type: 'fas',
      icon_name: 'comment-alt',
      context: 'update',
      psychomotor: [],
      psychomotors: [],
      result_psychomotor: {},
      result_psychomotors: [],
      studentId: null,
      student: null,
      psychomotor_remarks: {
        5: 'Excellent',
        4: 'Very Good',
        3: 'Good',
        2: 'Fair',
        1: 'Poor',
      },
      error: null,
      reload: false,
    }
  },
  apollo: {
    terms: {
      query: TERMS,
      variables() {
        return { schoolId: this.schoolId }
      },
    },
    psychomotors: {
      query: PSYCHOMOTORS,
      variables() {
        return { schoolId: this.schoolId }
      },
    },
  },
  watch: {
    termId() {
      this.fetchResultPsychomotors()
    },
    student(data) {
      this.studentId = parseInt(data.id)
      if (data.resultPsychomotors && !data.resultPsychomotors.id) {
        data.resultPsychomotors = null
      } else {
        this.context = 'update'
      }

      this.$store.commit('setSubMenus', [
        {
          name: 'Result Info',
          route: `/school/${this.schoolId}/school_class/${data.schoolClassId}/result_info/${data.id}/term_id/${this.termId}`,
        },
      ])
    },
  },
  methods: {
    initializeCreate() {
      const psychomotors = []
      this.psychomotors.forEach((item) => {
        this.$set(item, 'value', '1')
        this.$set(item, 'remark', 'Poor')
        psychomotors.push(item)
      })
      this.student['resultPsychomotors'] = {}
      this.student.resultPsychomotors.psychomotors = psychomotors
      this.context = 'add'
    },
    submit() {
      if (this.error) {
        this.$buefy.notification.open({
          duration: 5000,
          message: `Cannot perform this operation.<br>You must input the correct values.`,
          position: 'is-top-right',
          type: 'is-danger',
          hasIcon: true,
        })
        return
      } else {
        if (this.context === 'add') {
          this.$apollo
            .mutate({
              mutation: gql`
                mutation CreateAteResultPsychomotor(
                  $termId: Int!
                  $studentId: Int!
                  $psychomotors: JSON!
                ) {
                  createAteResultPsychomotor(
                    input: {
                      termId: $termId
                      studentId: $studentId
                      psychomotors: $psychomotors
                    }
                  ) {
                    ateResultPsychomotor {
                      id
                    }
                    errors
                  }
                }
              `,
              variables: {
                termId: this.termId,
                studentId: this.studentId,
                psychomotors: this.student.resultPsychomotors.psychomotors,
              },
            })
            .then((response) => {
              crudNotification(
                response.data.createAteResultPsychomotor.errors,
                'Successfully created.'
              ).then(() => {
                this.reload = true
              })
            })
            .catch((error) => {
              console.error(error)
            })
        } else {
          this.$apollo
            .mutate({
              mutation: gql`
                mutation UpdateAteResultPsychomotor(
                  $id: Int!
                  $psychomotors: JSON!
                ) {
                  updateAteResultPsychomotor(
                    input: { id: $id, psychomotors: $psychomotors }
                  ) {
                    ateResultPsychomotor {
                      id
                    }
                    errors
                  }
                }
              `,
              variables: {
                id: parseInt(this.student.resultPsychomotors.id),
                psychomotors: this.student.resultPsychomotors.psychomotors,
              },
            })
            .then((response) => {
              crudNotification(
                response.data.updateAteResultPsychomotor.errors,
                'Successfully updated.'
              ).then(() => {
                this.reload = true
              })
            })
            .catch((error) => {
              console.error(error)
            })
        }
      }
    },
    setRemark(psychomotor) {
      const input = document.getElementById(
        psychomotor.name.toLowerCase().replace(/\s/g, '-')
      )
      psychomotor.value = psychomotor.value.trim()
      if (
        parseInt(psychomotor.value) >= '1' &&
        parseInt(psychomotor.value) <= '5'
      ) {
        input.classList.remove('is-danger')
        this.error = false
      } else {
        input.classList.add('is-danger')
        this.error = true
      }

      psychomotor.remark = this.psychomotor_remarks[psychomotor.value]
    },
    fetchResultPsychomotors() {
      this.$apollo.addSmartQuery('student', {
        query: gql`
          query StudentTermQuery($student_id: ID!, $term_id: ID!) {
            studentTerm(studentId: $student_id, termId: $term_id) {
              id
              name
              ateMarksAverageScore
              resultPsychomotors {
                id
                psychomotors
              }
            }
          }
        `,
        variables: {
          student_id: this.studentId,
          term_id: this.termId,
        },
        fetchPolicy: 'cache-and-network',
        update: (data) => data.studentTerm,
        error(error) {
          this.$buefy.notification.open({
            duration: 5000,
            message: error.message,
            position: 'is-top-right',
            type: 'is-danger',
            hasIcon: true,
          })
        },
      })
    },
  },
  mounted() {
    this.$store.commit('setPageTitle', this.pageTitle)
    this.studentId = parseInt(this.$route.params.student_id)
    this.termId = parseInt(this.$route.params.term_id)

    fetchUser().then((user) => {
      this.user = user
      this.schoolId = user.school_id
    })
  },
}
</script>

<style scoped>
.item {
  margin-top: 8px;
  padding: 10px;
  border: 1px solid #c0c0c0c0;
}
.comment {
  height: auto;
  margin-top: 12px;
}

.footer-btn {
  padding-right: -10px;
}

#student-list {
  height: 600px;
  padding: 10px;
}

.card-item-right {
  justify-content: flex-end;
}
</style>
